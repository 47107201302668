import React, { useState } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PhotosPage = ({ data: { allPhotos } }) => {
  const [activePopup, setActivePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <Layout>
      <SEO title="Fotos" />
      <div className="site-container blogs-page">
        <div className="container">
          <div className="section-head">
            <h1 className="line-heading h2">{allPhotos.title}</h1>
          </div>
          <ul className="photos-page-list">
            {allPhotos.photos.map((photo, i) => {
              return (
                <li key={i} className="item">
                  <div
                    className="inner"
                    onClick={() => {
                      setActivePopup(true);
                      setSelectedItem(i);
                    }}
                  >
                    <Img
                      fluid={photo.fluid}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </li>
              );
            })}
          </ul>
          {activePopup ? (
            <div className="rg-popup">
              <span
                className="popup-layer"
                onClick={() => setActivePopup(false)}
              ></span>
              <div className="popup-inner">
                <i
                  className="fas fa-times"
                  onClick={() => setActivePopup(false)}
                ></i>
                <img
                  src={allPhotos.photos[selectedItem].file.url}
                  alt="popup-img"
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PhotosPage;

export const pageQuery = graphql`
  query PhotosPageQuery {
    allPhotos: contentfulPhotos {
      title
      photos {
        file {
          url
        }
        fluid(maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`;
